import React from "react";
import Section from "../Section";
import BlogSectionStyle2 from "../Section/BlogSection/BlogSectionStyle2";
import Breadcrumb from "../Breadcrumb";
import { pageTitle } from "../../helpers/PageTitle";
const blogData = [
  {
    title: "Symptoms before period: important information",
    thumbUrl: "images/blog/post_1.jpeg",
    date: "March 12 , 2024",
    btnText: "Learn More",
    href: "/blog/blog-details",
    socialShare: true,
  },
];

export default function Blog() {
  pageTitle("Blog");
  return (
    <>
      <Section topMd={170} bottomMd={96} bottomLg={70}>
        <Breadcrumb title="Understanding the Psychological and Lifestyle Aspects" />
      </Section>
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <BlogSectionStyle2 data={blogData} />
      </Section>
    </>
  );
}
